$color_1: #848484;
$color_2: #272727;
$color_3: #0b70d1;
$color_4: #fff;
$color_5: #575757;
$font-family_1: Segoe UI;
$background-color_1: #fff;
$background-color_2: #f2f6fe;
$background-color_3: #f7f7fa;

.lightTheme{
      .RefreshText {
        h4 {
          margin-bottom: 0;
          margin-top: 12px;
          font-size: 20px;
          font-weight: normal;
          font-family: Segoe UI;
          color: #272727;
          margin-right: 15px;

          height: 1.438rem;
          font-size: 1rem;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
        }
      }
      .integratedDeviceText {
        margin: 0;
        font-family: Segoe UI;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.01px;
        color: #272727;
        @include devices(laptop_xl) {
          font-size: 12px;
        }
        @include devices(laptop_lg) {
          font-size: 11px;
        }
        @include devices(laptop_md) {
          font-size: 11px;
        }
        @include devices(laptop_sm) {
          font-size: 10px;
        }
      }
      .HeightScrollSitesGrid {
          height: calc(100vh - 145px);
      }    
      .HMHeader {
          @include floatLeft;
      }
      .RowClass {
          @include floatLeft;
          width: 100%;
          padding: 10px 0px;
          margin: 0;
      }
      .CardBoxWithBorder {
          height: 160px;     
          margin: 10px;
          @include CardBoxBGShadow;
          background-color: #F8F8F8 !important;
          float: left;
          width: calc(100% - 20px);
          @include devices(laptop_xxl) {
            height: 175px; 
          }
          @include devices(laptop_xl) {
            height: 185px; 
          }
          @include devices(laptop_lg) {
            height: 175px; 
          }
          @include devices(laptop_sm) {
            height: 175px; 
          }
      }
      .CardBoxImage {
          width: 55px;
          height: 55px;
          border-radius: 9px;
          text-align: center;
          @include flexCenter;
          @include floatLeft;
      }
      .CardBoxContent {
          margin: 20px;
          height: auto;
          @include  display-flex;
      }
      .CardBoxText {
          margin-left: 6px;
          width: 78%;
          //  @include  display-flex;
          // justify-content: space-between;
          h4 {
            margin-bottom: 0;
            margin-top: 12px;
            font-size: 20px;
            font-weight: normal;
            font-family: $font-family_1;
            color: $color_2;
            @include devices(laptop_lg) {
              font-size: 17px;
            }
            @include devices(laptop_md) {
              font-size: 16px;
            }
          }
          @include devices(laptop_lg) {
            // width: 70%;
          }
          @include devices(laptop_md) {
            width: 73%;
          }
          @include devices(laptop_sm) {
            width: 72%;
          }
      }
      .SystemHealthCardText{
        margin-bottom: 0;
        margin-top: 12px;
        font-size: 20px;
        font-weight: normal;
        font-family: $font-family_1;
        color: $color_2;
        float: left;
        margin-left: 5px;
        @include devices(laptop_lg) {
          font-size: 17px;
        }
        @include devices(laptop_md) {
          font-size: 16px;
        }
      }
      .StatusText {
        @include  display-flex;
        margin-top: 35px;
      }
      .StatusText1 {
        margin-top: 5px;
      }
      .StatusText2 {
        margin-top: 30px;
      }
      .StatusText3 {
        margin-top: 30px;
      }
      #tooltip1 {
        @include  display-flex;
        flex-direction: column;
        height: auto;
      }
      #tooltip2 {
        @include  display-flex;
        flex-direction: column;
        height: auto;
      }
      #tooltip3 {
        >.tooltip-inner {
          @include  display-flex;
          flex-direction: column;
          height: auto;
        }
      }
      .timeTooltipCount {
        max-width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .deviceNameTooltipText {
        width: 100px;
        @include floatLeft;
      }
      .deviceNameTooltipName {
        width: 60px;
        word-break: keep-all;
        @include floatLeft;
      }
    .siteNameTooltipText {
        width: 80px;
        @include floatLeft;
    }
    .siteNameTooltipName {
        width: 80px;
        @include floatLeft;
        word-wrap: break-word;
    }
    .statusValueText {
        margin: 0;
        font-family: $font-family_1;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.01px;
        color: $color_1;
    }
    .statusValueP {
        margin: 0;
        font-weight: 500;
        line-height: 18px;
        font-family: $font-family_1;
        font-size: 25px;
        font-weight: 600;
        letter-spacing: 0.03px;
        color: $color_2;
    }
    .OnlySingleValue {
        margin-top: 50px;
        text-align: center;
        width: 90%;
    }
    .DeviceCurrentStatusDiv {
        margin: 20px 10px 15px 10px;
        @include floatLeft;
        @include CardBoxBGShadow;
        td {
            cursor: pointer;
            font-size: 14px;
            font-family: $font-family_1;
            font-weight: normal;
            line-height: 1.36;
            letter-spacing: normal;
            text-align: left;
            padding-left: 20px !important;
        }
        .online-color {
            font-weight: 600 !important;
            color: #7BB526;
        }
        .offline-color {
            font-weight: 600 !important;
            color: #F32C22;
        }
    }
    .DeviceTableHeader {
        border-bottom: 1px solid #e6e6e6;
        @include flexSpaceBetween;
        p {
          margin: 15px;
          font-size: 15px;
          font-weight: 600;
          color: $color_2;
        }
        span{
          color: $color_2;
        }
    }
    .TrendchartDevice {
        height: 300px;
        border: 1px solid #a5a5a5;
        border-radius: 7px;
        margin: 10px;
        padding: 10px;
        @include floatLeft;
        width: 98%;

        @include devices(laptop_lg) {
          height: 230px;
        }
    }
    .LegendModelDiv {
        @include floatLeft;
        margin-left: 10px;
    }
    .MainLegendDiv {
        @include  display-flex;
        align-items: center;
        p{
          color: $color_2;
        }
    }
    .LegendColorDiv {
        width: 25px;
        height: 6px;
        border-radius: 3px;
        margin-right: 2px;
    }
    .healthMonitorModel {
        .healthmonitoringTable {
          margin: 10px auto;
        }
        .close {
          line-height: 1.5;
        }
    }
    .deviceModelHeader {
        width: 100%;
        @include flexSpaceBetween;
        .btn-outline-primary {
          height: 34px;
          margin-top: 5px;
          line-height: 10px;
          color: $color_3 !important;
          &:hover {
            color: $color_4 !important;
          }
        }
        p{
          color: $color_2;
        }
    }
    .rs-picker-menu {
        // z-index: 10000;
        z-index: 99999;

    }
    .width60pct {
        width: 60%;
    }
    .width70pct {
        width: 70%;
    }
    .width80pct {
        width: 80%;
    }
    .HMWidgetLabelSkeleton {
        width: 75%;
        height: 10px;
    }
    .HMWidgetValueSkeleton {
      width: 50%;
      height: 10px;
      @include floatLeft;
    }
    .offlineDeviceText {
        margin: 0;
        font-family: $font-family_1;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.01px;
        color: $color_1;
        display: inline-block;
        margin-bottom: 0px;
        width: 95px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-left: 3px;
        margin-top: 3px;
    }
    .OfflineDeviceDiv {
       @include  display-flex;
      align-items: center;
      width: 60px;
    }
    .CameraTamperingModel {
      tbody {
        height: calc(100vh - 600px) !important;
      }
    }
    .CameraInfoDiv {
      @include absolutePosition;
      right: -35px;
      top: -10px;

      @include devices(laptop_xl) {
        right: -8px
      }
      @include devices(laptop_lg) {
        right: -8px
      }
      @include devices(laptop_md) {
        @include absolutePosition;
        right:-15px;
        top:-10px;
      }
      @include devices(laptop_sm) {
        @include absolutePosition;
        right:-15px;
        top:-10px;
      }
    }
    .TrendSelectionDateP {
        margin-bottom: 0px;
        text-align: end;
        margin-right: 10px;
        font-size: 13px;
        font-weight: normal;
        @include floatRight;
    }
    .DonutChartWidthDiv {
      width: 55%;
    }
    .InfoImg {
      img {
        vertical-align: top;
        height: 14px;
      }
    }
    .offlineDevDiv {
      width: 60%;
    }
    .noRecordFound {
      @include relativePosition;
      height: 100%;
    }
    .noRecordFoundText {
      @include absolutePosition;
      top: 50%;
      left: 46%;
    }
    .OnHoverMouseTableRow {
      tr {
        &:hover {
          td {
            background-color: $background-color_2 !important;
            text-decoration: underline;
          }
        }
      }
    }
    .PaginationDiv {
      &:disabled {
        opacity: .3;
        background-color: $background-color_3 !important;
        color: $color_5;
      }
    }

    .FromDateFontSize {
      font-size: 14px !important;
      font-weight: 500 !important;
      color: $color_2;
    }

    input.forDisabled:disabled {
      opacity: .3;
      background-color: #f7f7fa !important;
      color: #575757;
    }
    .HMWidgetMainDiv {
      background-color: #ffffff;
      border-radius: 13px;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
      height: 380px;
      margin: 10px 10px 15px 10px;
      padding: 10px; 
      @include devices(laptop_xxl) {
        height: 420px;
      }
      @include devices(laptop_xl) {
        height: 435px;
      }
      @include devices(laptop_lg) {
        height: 415px;
      }
      @include devices(laptop_md) {
        height: 420px;
      }
    }
    .carousel .carousel-indicators {
      width: 200px;
      ol {
        top: 360px;
      }
      li {
      width: 10px;
     height: 10px;
     border-radius: 100%;
     background-color: #b0cde9;
    }
    }
    .carousel .carousel-indicators .active {
      background-color: #0b70d1;
    }
    

    .alice-carousel__dots {
      display: none;
    }

    .CarouselPrev {
      background-color: #ffffff;
    }
    .CarouselNext {
      background-color: #ffffff;
    }

    .IntegratedTextDiv{
      float: left;
      width: 48%;
    }
    .IntegratedChartDiv{
      float: right;
      width: 45%;
    }
}

/* @media screen and (min-width: 800px) and (max-width: 1023px) {
    .HMSites-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 0rem;
        justify-items: center;
      }
    .HMSitesGrid {
    width: 33.5vw;
  }
} */
/* 
@media screen and (min-width: 1024px) and (max-width: 1279px) {
      .HMSites-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 0rem;
        justify-items: center;
      }
      .HMSitesGrid {
        width: 24.3vw;
      }
      .TrendchartDevice{
          height: 235px;
      }  
      .healthmonitoringTable tbody {
        height: 450px !important;
      }
      .healthMonitorModel  .healthmonitoringTable tbody {
        height:  200px !important;
      }
      .CardBoxWithBorder {
        height: 180px;
      }
      .CardBoxText h4{
          font-size: 16px !important;
      }
      .statusValueText{
          font-size: 14px !important;
      }
      .statusValueP{
          font-size: 20px !important;
      }
      .CameraInfoDiv{
        position:absolute;
        right:-15px;
        top:-10px;
      }
}

@media screen and (min-width: 1280px) and (max-width: 1365px) {
    .HMSites-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 0rem;
        justify-items: center;
      }
      .HMSitesGrid {
        width: 26.3vw;
      }


      .TrendchartDevice{
          height: 235px;
      }  
      .healthmonitoringTable tbody {
        height: 450px !important;
      }
      .healthMonitorModel  .healthmonitoringTable tbody {
        height:  200px !important;
      }
      .CardBoxWithBorder {
        height: 170px;
      }
      .CardBoxText h4{
          font-size: 17px !important;
      }
      .statusValueText{
          font-size: 14px !important;
      }
      .statusValueP{
          font-size: 20px !important;
      }
      .CameraInfoDiv{
        position:absolute;
        right:-15px;
        top:-10px;
      }
      .offlineDevDiv{
        width: 45%;
      }

      .StatusText3{
        margin-top: 12px!important;
      }
      .healthmonitoringTable .SearchViewInput {
        width: 89%;
      }

}

@media screen and (min-width: 1366px) and (max-width: 1439px) {
      .HMSites-list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 0rem;
        justify-items: center;
      }
      .HMSitesGrid {
        width: 19.6vw;
      }  
      .TrendchartDevice{
          height: 235px !important;
      }  
      .healthmonitoringTable tbody {
        height: 500px !important;
      }
      .healthMonitorModel  .healthmonitoringTable tbody {
        height:  200px !important;
      }
      .CardBoxWithBorder {
        height: 170px;
      }
      .CardBoxText h4{
          font-size: 18px !important;
      }
      .statusValueText{
          font-size: 14px !important;
      }
      .statusValueP{
          font-size: 20px !important;
      }
      .CameraInfoDiv{
        position:absolute;
        right:-15px;
        top:-10px;
      }
      .DonutChartWidthDiv{
        width: 40%;
      }
      .StatusText3{
        margin-top: 12px !important;
      }
      .healthmonitoringTable .SearchViewInput {
        width: 89%;
      }

}

@media screen and (min-width: 1440px) and (max-width: 1535px) {
      .HMSites-list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 0rem;
        justify-items: center;
      }

      .HMSitesGrid {
        width: 20.3vw;
      }
      
      .TrendchartDevice{
          height: 235px;
      }  
      .healthmonitoringTable tbody {
        height: 353px !important;
      }
      .healthMonitorModel  .healthmonitoringTable tbody {
        height:  200px !important;
      }
      .CardBoxWithBorder {
        height: 170px;
      }
      .CardBoxText h4{
          font-size: 18px !important;
      }
      .statusValueText{
          font-size: 14px !important;
      }
      .statusValueP{
          font-size: 20px !important;
      }
      .CameraInfoDiv{
        position:absolute;
        right:-15px;
        top:-10px;
      }
      .DonutChartWidthDiv{
        width: 45%;
      }
      .StatusText3{
        margin-top: 12px !important;
      }
      .healthmonitoringTable .SearchViewInput {
        width: 89%;
      }

}

@media screen and (min-width: 1536px) and (max-width: 1679px) {
      .HMSites-list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 0rem;
        justify-items: center;
      }
      .HMSitesGrid {
        width: 20.3vw;
      }  
      .TrendchartDevice{
          height: 235px;
      }  
      .healthmonitoringTable tbody {
        height: 260px !important;
      }
      .healthMonitorModel  .healthmonitoringTable tbody {
        height:  200px !important;
      }
      .CardBoxText h4{
          font-size: 18px !important;
      }
      .statusValueText{
          font-size: 14px !important;
      }
      .statusValueP{
          font-size: 20px !important;
      }
      .CameraInfoDiv{
          position:absolute;
          right:-15px;
          top:-10px;
      }
      .DonutChartWidthDiv{
          width: 48%;
      }
      .offlineDevDiv{
        width: 49%;
      }
      .StatusSearchText .SearchViewInput {
        width: 90%;
    }
}

@media screen and (min-width: 1680px) and (max-width: 1919px) {
    .HMSites-list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-gap: 0rem;
      justify-items: center;
    }
    .HMSitesGrid {
      width: 20.7vw;
    }
    .healthmonitoringTable tbody {
      height: 353px !important;
    }
    .healthMonitorModel  .healthmonitoringTable tbody {
      height:  180px !important;
    }
    .CameraInfoDiv{
      position:absolute;
      right:-30px;
      top:-10px;
    }
    .DonutChartWidthDiv{
      width: 51%;
    }
    .offlineDevDiv{
      width: 50%;
    }
.StatusModel{
  min-width: 70vw;
}
.StatusModel .modal-header{
  height: 6vh;
  padding: 5px 10px 0px 10px
}
.StatusHeaderDiv{
  min-width: 67vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.StatusTableDiv{
  overflow: auto;
  height: 70vh;
}
.StatusTableDiv thead{
  background-color: #dbe6fc;
  height: 85px; 
  position: sticky; 
  top: 0; 
  z-index: 1;
}

.StatusTableDiv thead th{
  text-align: left;
  color: #535353;
  font-size: 15px;
  border-top: none;
  width: 12vw;
}
.StatusTableDiv thead th p{
  margin-bottom: 0px !important;
}
.StatusTableDiv thead th:first-child{  
  border-top-left-radius: 5px;
}

.StatusTableDiv thead th:last-child{  
  border-top-right-radius: 5px;
}
.StatusTableDiv th, td {
  text-align: left;
}
.StatusTableDiv td{
  font-size: 0.8vw;
  padding:  0.3vw 0.3vw 0.3vw 1vw !important;
}

.StatusSearchText .SearchViewInput{
  width: 85%;
}


.DateTimeTableSpan{
  margin-right: 3vw;
}
.grid-container {
  display: grid;
}

.grid-container--fit {
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.grid-element1 {
  display: none; 
}
input.forDisabled:disabled {
  opacity: .3;
  background-color: #f7f7fa !important;
  color: #575757;
}
}

@media screen and (min-width: 1920px) {
} */
