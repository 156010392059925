@mixin display-flex {
	display: flex;
}
@mixin display-none{
	display: none;
}
@mixin display-block{
	display: block;
}
@mixin floatLeft{
	float: left;
}
@mixin floatRight{
	float: right;
}
@mixin relativePosition{
	position: relative;
}
@mixin absolutePosition{
	position: absolute;
}
@mixin flexCenter{
	display: flex;
	align-items: center;
	justify-content: center;
}
@mixin flexSpaceBetween{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
@mixin flexStart{
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
@mixin overflowY{
	overflow-x: hidden;
	overflow-y: auto;
}
@mixin UtilityModelText{
	letter-spacing: 0.02px;
	opacity: 1;
}
@mixin fontStyle{
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
}
@mixin statusStyle{
	height: 12px;
	width: 12px;
	border-radius: 50%;
	margin-right: 5px;
}
@mixin line-height{
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
}
@mixin ValueText-Style{
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
	display: inline-block;
	margin: 0px;
}

// HM page common mixins start
@mixin CardBoxBGShadow{
	background-color: #ffffff;
	border-radius: 13px;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
}
// HM page common mixins end

@mixin NodeHeadingFont{	
	font-family: Segoe UI;
	font-weight: 700;
	line-height: 1.1;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
	float: left;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;  
	overflow: hidden;
}