
/*Topbar Start*/


.topbar {
    /* box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12); */
    box-shadow: 0 0 11px rgba(0,0,0,.13);
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: auto;
    right: 0;
    z-index: 1020;
    background-color: #fff;
    color: black;
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    margin-left: 240px;
    height: 65px;
}
.topbarLeft {
    margin-left: 20px;
    float: left;
    align-items: center;
    display: inline-flex;
    width: 30%;
}

.topbarRight {
    float: right;
    /*width: 64%;*/
    width: 70%;
    text-align: right;
    display: inline-flex;
    /* align-items: baseline; */
    justify-content: flex-end;
}

.tbRoundLogo {
    cursor: pointer;
}

.tbRoundLogo img {
    width: 3.5rem;
    height: 3.5rem;
    margin: 0 0.531rem 0 9px;
    margin-right: 15px;
}

.poweredByTxt {
    margin: 0 1.952rem 0.16rem 0;
    font-size: 0.75rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.08px;
    text-align: left;
    color: #7a7c8a;
    display: inline-block;
}

.cognyfloLogo {
    cursor: pointer;
}

.topLogo2 {
    display: inline-grid;
}

.logoDivider {
    border-left: 1px solid #eaeaec;
    height: 33px;
    margin-right: 15px;
}

.mainDivDateTime {
    display: flex;
    align-items: center;
}

.topDate {
    /* height: 1.813rem; */
   /*  margin: 1.125rem 0.625rem 1.125rem 0rem;
    padding: 0.375rem 0.438rem 0.313rem; */
    border-radius: 8px;
    font-size: 0.813rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #131415;
    min-width: 130px;
}

.topTime {
   /*  margin: 1.125rem 1.5rem 1.125rem 0.625rem;
    padding: 0.375rem 0.563rem 0.313rem 0.688rem; */
    /* height: 1.813rem; */
    border-radius: 8px;
    font-size: 0.813rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #131415;
    min-width: 75px;

}

.topNotification {
    margin: 1.3rem 1.688rem 1.3rem 1.5rem;
}

.topProfilePic {
    margin: 0.469rem 0.5rem;
}

.profileDpDwn {
    display: inline-block;
    text-align: left;
    text-align: left;
    border-color: transparent !important;
    top: 5%;
}

.profileName1 {
    margin: 0.063rem 0.5rem 0rem 0.563rem;
    font-size: 0.813rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #131415;
    display: block;
}

.profileName2 {
    margin: 0.159rem 4.063rem 0rem 0.5rem;
    font-size: 0.625rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #748b94;
    display: block;
}

.profileDpDwn .dropdown-toggle::after {
    color: #027aaa;
}
.fa-2x{
    font-size: 1.2rem;
}

.topbarDropdownMenu {
    transform: translate(0px, 50px) !important;
}

/*Topbar End*/

