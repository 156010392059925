$color_1: #ffffff;
$color_2: #7a7c8a;
$color_3: #000000;
$color_4: #3c4043;
$color_5: #0B70D1;
$font-family_1: Segoe UI;
$font-family_2: FontAwesome;
$background-color_1: #ffffff;
$background-color_2: #0b70d1;
$background-color_3: #f2f6fe;
$background-color_4: #dbe6fc;

.lightTheme{
    
    .sidebar {
        position: fixed;
        z-index: 1019;
        width: 190px;
        height: 100vh;
        background-color: $background-color_1;
        margin-top: 0px;
        transition: all 0.5s ease;
        .sidebar-nav {
            @include relativePosition;
            -ms-flex: 1;
            flex: 1;
            width: 190px;
            margin: 0px !important;
            -ms-overflow-style: none;
            scrollbar-width: thin;
            margin-top: 0px !important;
            height: calc(100vh - 75px);
            padding-top: 5px;
            @include overflowY;
            .versionTextDiv {
                @include display-block;
                text-align: center;
                p {
                    color: $color_4;
                    font-size: 12px;
                    margin-bottom: 0; 
                }
            }
        }
        .navList {
            width: 190px;
            -ms-flex-direction: column;
            flex-direction: column;
            min-height: 100%;
            padding: 0px;
            margin: 0px;
        }
        .nav-item {
            @include relativePosition;
            margin: 0;
            margin-right: 5px;
            .nav-link {
                padding: 0.75rem 1.5rem;
                @include display-flex;
                align-items: center;
                color: $color_2;
                text-decoration: none;
                height: 48px;
            }
        }
        .nav-dropdown-items {
            max-height: 1500px;
            background: $background-color_4;
            color: $color_1;
            padding-left: 27px;
            border-bottom-right-radius: 5px;
            margin-bottom: 6px;
            margin-right: 5px;
        }
        .nav-item.open {
            .nav-link {
                background-color: $background-color_2;
                color: $color_1;
                border-top-right-radius: 5px;
            }
        }
        .nav-item.noDrop{
            .nav-link {
                border-bottom-right-radius: 5px;
            }
        }
        .nav-item.highlight {
            .nav-link {
                background-color: $background-color_2;
                color: $color_1;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
        .nav-label {
            width: 9vw;
            font-size: 15px;
            padding-left: 10px;
            text-align: left;
            font-family: $font-family_1;
            font-weight: normal;
        }
        .nav-dropdown-toggle {
            &::before {
                content: "";
                font-family: $font-family_2;
                font-style: normal;
                font-weight: normal;
                text-decoration: inherit;
                color: $color_1;
                font-size: 0.8vw;
                @include absolutePosition;
                top: 13px;
                right: 8px;
            }
        }
    }
    .sidebar-minimized {
        .sidebar {
            width: 0px;
            .nav-item {
                width: 0px;
                overflow: hidden;
                &:hover {
                    width: 0px;
                    overflow: visible;
                    .nav-link {
                        background-color: $background-color_2;
                        color: $color_1;
                        border-top-right-radius: 5px;
                    }
                }
            }
            .nav-dropdown.open {
                width: 0px;
            }
            .singleItem {
                &:hover {
                    .nav-link {
                        background-color: $background-color_2;
                        color: $color_1;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                    }
                }
            }
            .navList {
                .nav-dropdown {
                    &:hover {
                        .nav-dropdown-items {
                            @include absolutePosition;
                            left: 0px;
                            display: inline;
                            width: 0%;
                        }
                    }
                    .nav-dropdown-items {
                        @include display-none;
                        height: 12vh;
                        @include overflowY;
                        max-height: 12vh;
                        background-color: $background-color_3;
                    }
                }
            }
            .nav-dropdown-items {
                @include absolutePosition;
                left: 0px;
                width: 0px;
                margin-right: 0px;
                max-width: 70%;
                .nav-item {
                    .nav-link {
                        width: 0px;
                        color: $color_1;
                    }
                }
            }
            .nav-dropdown-toggle {
                &::before {
                    content: "";
                }
            }
            .sidebar-nav{
                width: 0px;
            }
            .navList{
                width: 0px;
            }
        }
        .versionTextDiv {
            width: 0px;
            @include display-block;
            text-align: center;
            p {
                color: $color_4;
                font-size: 12px;
                margin-bottom: 0; 
            }
        }
        .minVersion {
            @include display-none;
        }
        .maxVersion {
            @include display-none;
        }
    }
    .nav-link1 {
        padding: 0.75rem 0.1rem;
        @include display-flex;
        align-items: center;
        color: $color_2;
        text-decoration: none !important;
        margin-left: 27px;
        font-size: 14px;
        span{
            line-height: 17px;
        }
        &:hover {
            text-decoration: none;
            color: $color_3;
        }
        &:active {
           /*  font-weight: 700; */
            color: $color_3;
        }
    }
    .navWidth {
        width: 7.5vw;
        font-size: 0.9vw;
        line-height: 18px;
        padding-left: 10px;
        text-align: left;
    }
    .side-images {
        margin-right: 10px;
        margin-left: 7px;
    }
    .navList {
        ul {
            @include display-block;
        }
    }
    .minVersion {
        @include display-none;
    }
    .ModalBodyDiv {
        /* width: 35vw; */
        @include display-flex;
        align-items: center;
        flex-direction: column;
        p {
            font: normal normal 600 25px/33px Segoe UI;
            @include UtilityModelText;
        }
    }   
    .Maxsize {
        font-size: 11px;
        color: #8e8f8f;
        height: 20px;
        margin-top: 0.5rem;
        margin-left: 0.2rem;
    }
    .downloadUtilityMainclass {
        .modal-content{
            height: 400px;
            width: 700px;
        }
        .modal-header {
            .close {
                font-size: 2rem !important;
                color: $color_5 !important;
                opacity: 1;
                padding: 0.5rem 1rem !important;
            }
        }
    }
    .UtilityMHLogo {
        height: 110px;
        width: 200px;
        margin-top: -30px;
    }
    .UtilityText {
        font: normal normal 600 16px/21px Segoe UI !important;
        @include UtilityModelText;
    }
    .navPage{
        &::before{
            display: none !important;
        }
    }


    /* For dashed border design css start */

    
    .borderLineList {
        margin: 0 0 0 1em;
        padding: 0;
        list-style: none;
        position: relative;
        ul {
            margin: 0 0 0 1em;
            padding: 0;
            list-style: none;
            position: relative;
            margin-left: 0em;
            &:before {
                content: "";
                display: block;
                width: 0;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 28px;
                border-left: 1px dashed #000000;
            }
        }
        &:before {
            content: "";
            display: block;
            width: 0;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 28px;
            border-left: 1px dashed #000000;
        }
        li {
            margin: 0;
            padding: 0 0em;
            position: relative;
            &:before {
                content: "";
                display: block;
                width: 20px;
                height: 0;
                border-top: 1px dashed #000000;
                margin-top: -1px;
                position: absolute;
                top: 1.4em;
                left: 1px;
            }
            &:last-child {
                &:before {
                    background: #dbe6fc;
                    height: auto;
                    top: 1.4em;
                    bottom: 0;
                    left: 0px;
                }
            }
        }
        .active-sub-menu {
            span {
                font-weight: 600;
                font-size: 0.938rem;
                line-height: 18px;
            }
        }
    }
    ul.borderLineList {
        &:before {
            border-left: none;
        }
        >li {
            &:before {
                border-top: none;
            }
        }
    }

    .termsText{
        color: #000;
        font-size: 13px;
        font-weight: 600;
    }
    
  /* For dashed border design css end */

    
    
}

