$color_1: black;
$color_2: #7a7c8a;
$color_3: #131415;
$color_4: #748b94;
$color_5: #027aaa;
$background-color_1: #fff;
$border-color_1: transparent;


.lightTheme {
	.topbar {
		box-shadow: 0 0 11px rgba(0,0,0,.13);
		@include flexCenter;
		width: 100%;
		box-sizing: border-box;
		position: fixed;
		top: 0;
		left: auto;
		right: 0;
		z-index: 1020;
		background-color: $background-color_1;
		color: $color_1;
		transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
		margin-left: 240px;
		height: 65px;
	}
	.topbarLeft {
		margin-left: 20px;
		@include floatLeft;
		align-items: center;
		display: inline-flex;
		width: 30%;
	}
	.topbarRight {
		@include floatRight;
		width: 70%;
		text-align: right;
		display: inline-flex;
		justify-content: flex-end;
	}
	.tbRoundLogo {
		cursor: pointer;
		img {
			width: 3.5rem;
			height: 3.5rem;
			margin: 0 0.531rem 0 9px;
			margin-right: 15px;
		}
	}
	.poweredByTxt {
		margin: 0 1.952rem 0.16rem 0;
		font-size: 0.75rem;
		@include fontStyle;
		color: $color_2;
		font-weight: 600;
		letter-spacing: -0.08px;
		display: inline-block;
	}	
	.dropdown {
		/* width: 8.375rem; */
		/* height: 2.375rem; */
		border-radius: 8px;
		border: solid 1px #bcbdbe;
		background-color: $background-color_1;
	}
	.BellIconStyle{
		path{
			stroke: #0b70d1;
		}
	}
	.cognyfloLogo {
		cursor: pointer;
		width: 150px;
		height: 50px;
	}
	.topLogo2 {
		display: inline-grid;
	}
	.logoDivider {
		border-left: 1px solid #eaeaec;
		height: 33px;
		margin-right: 15px;
	}
	.mainDivDateTime {
		@include display-flex;
		align-items: center;
	}
	.topDate {
		border-radius: 8px;
		font-size: 0.813rem;
		@include fontStyle;
		text-align: center;
		color: $color_3;
		min-width: 130px;
	}
	.topTime {
		border-radius: 8px;
		font-size: 0.813rem;
		@include fontStyle;
		text-align: center;
		color: $color_3;
		min-width: 75px;
	}
	.topNotification {
		margin: 1.3rem 1.688rem 1.3rem 1.5rem;
	}
	.topProfilePic {
		margin: 0.469rem 0.5rem;
	}
	.profileDpDwn {
		display: inline-block;
		text-align: left;
		text-align: left;
		border-color: $border-color_1 !important;
		top: 5%;
		.dropdown-toggle {
			&::after {
				color: $color_5;
			}
		}
	}
	.profileName1 {
		margin: 0.063rem 0.5rem 0rem 0.563rem;
		font-size: 0.813rem;
		@include fontStyle;
		text-align: right;
		color: $color_3;
		display: block;
	}
	.profileName2 {
		margin: 0.159rem 4.063rem 0rem 0.5rem;
		font-size: 0.625rem;
		@include fontStyle;
		color: $color_4;
		display: block;
	}
	.fa-2x {
		font-size: 1.2rem;
	}
	.topbarDropdownMenu {
		transform: translate(0px, 50px) !important;
	}
	label.ThemeLabel {
			width: 50px;
			height: 24px;
			position: relative;
			display: block;
			background: #ebebeb;
			border-radius: 200px;
			box-shadow: inset 0px 5px 15px rgba(0,0,0,0.4), inset 0px -5px 15px rgba(255,255,255,0.4);
			cursor: pointer;
			transition: 0.3s;
			&:after {
				content: "";
				width: 16px;
				height: 16px;
				position: absolute;
				top: 4px;
				left: 5px;
				background: linear-gradient(180deg,#ffcc89,#d8860b);
				border-radius: 50%;
				box-shadow: 0px 5px 10px rgba(0,0,0,0.2);
				transition: 0.3s;
			}
		}
		.ThemeCheckbox {
			width: 0;
			height: 0;
			visibility: hidden;
			&:checked {
				+ {
					.ThemeLabel {
						background: #242424;
						&:after {
							left: 45px;
							transform: translateX(-10%);
							background: linear-gradient(180deg,#777,#3a3a3a);
						}
						svg.sun {
							fill: #7e7e7e;
						}
						svg.moon {
							fill: #fff;
						}
					}
				}
			}
		}
		.ThemeLabel {
			&:active {
				&:after {
					width: 0px;
				}
			}
			svg {
				position: absolute;
				width: 10px;
				top: 7px;
				z-index: 100;
			}
			svg.sun {
				left: 8px;
				fill: #fff;
				transition: 0.3s;
			}
			svg.moon {
				left: 32px;
				fill: #7e7e7e;
				transition: 0.3s;
			}
		}

		.switch {
			position: relative;
			display: inline-block;
			width: 60px;
			height: 34px;
		  }
		  
		  /* Hide default HTML checkbox */
		  .switch input {
			opacity: 0;
			width: 0;
			height: 0;
		  }
		  
		  /* The slider */
		  .slider {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: #ccc;
			-webkit-transition: 0.4s;
			transition: 0.4s;
			height: 20px;
		  }
		  
		  .slider:before {
			position: absolute;
			content: "";
			height: 30px;
			width: 30px;
			left: 0px;
			bottom: 4px;
			top: 0;
			bottom: 0;
			margin: auto 0;
			-webkit-transition: 0.4s;
			transition: 0.4s;
			box-shadow: 0 0px 15px #2020203d;
			background: white url('https://i.ibb.co/FxzBYR9/night.png');
			background-repeat: no-repeat;
			background-position: center;
		  }
		  
		  input:checked + .slider {
			background-color: #2196f3;
			// height: auto;
		  }
		  
		  input:focus + .slider {
			box-shadow: 0 0 1px #2196f3;
		  }
		  
		  input:checked + .slider:before {
			-webkit-transform: translateX(24px);
			-ms-transform: translateX(24px);
			transform: translateX(24px);
			background: white url('https://i.ibb.co/7JfqXxB/sunny.png');
			background-repeat: no-repeat;
			background-position: center;
		  }
		  
		  /* Rounded sliders */
		  .slider.round {
			border-radius: 14px;
		  }
		  
		  .slider.round:before {
			border-radius: 50%;
		  }
		  .DarkThemeButtons {
			height: 25px;
			width: 30px;
			border: solid #000000;
			border-width: 1px 1px 1px 0px ;
			border-radius: 0px 5px 5px 0px;
		  }
		  .LightThemeButton {
			height: 25px;
			width: 30px;
			border: 1px solid #000000;
			border-radius: 5px 0px 0px 5px;
		  }
		  .alertSoundText {
			display: flex;
			align-items: center;
			margin-right: 15px;
			p {
				margin-right: 8px;
				font-size: 13px;
			}
		  }
}



