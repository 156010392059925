$color_1: #8a8a8a;
$color_2: #4e4e4e;
$color_3: #4a595f;
$color_4: #272727;
$color_5: #000000;
$background-color_1: #ffffff;
$background-color_2: #eff5f8;

.lightTheme{
    .ReportAccordianTitleIcon {
        @include floatLeft;
    }
    .ReportAccordionMainContainer {
        background-color: $background-color_1;
        @include CardBoxBGShadow;
    }
    .ReportAccordianArrow {
        @include floatRight;
    }
    .ReportRightOptions {
        @include floatRight;
        img {
            @include floatLeft;
            margin: 0px 5px 0px 0px;
        }
        p {
            @include floatLeft;
            font-size: 12px;
            color: $color_2;
            padding: 0px;
            margin: 0px;
        }
    }
    .ReportSubTitleText {
        @include floatLeft;
        font-size: 12px;
        color: $color_2;
        padding-left: 15px;
        margin: 0px;
    }
    .ReportTableHeader {
        background-color: $background-color_2;
    }
    .ReportTableAlertSeverity {
        font-weight: bold;
    }
    .ReportTableFontBold {
        font-weight: bold;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .ReportTableHeadReport{
        .SearchViewInput{
            background-color: transparent;
        }
    }
    .ReportAccordionHeader {
        margin: 0.6rem 0;
        padding: 0.937rem 1.957rem 0.813rem 0.983rem;
        width: 100%;
        border: none;
        &:focus {
            outline: none;
        }
    }
    .report-title-head {
        display: inline-flex;
        width: 100%;
    }
    
    .report-table {
        margin-bottom: 0px !important;
        th {
            border-bottom: none !important;
            border-top: none !important;
        }
        tbody {
            >tr {
                &:last-child {
                    >td {
                        border-bottom: 0 !important;
                    }
                }
            }
        }
    }
    .ReportAccordianTitleText {
        @include floatLeft;
        h5 {
            color: $color_4;
        }
    }
    .profile-name {
        height: 1.125rem;
        font-size: 0.813rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: $color_4;
    }
    .inputSearchLabelContainer {
        @include relativePosition;
        border: 1px solid #ccc;
        background-color: $background-color_1;
        width: 350px;
    }
    .ResizeTableColumn{
        resize: both;
        overflow: auto;
        width: 120px;
        height: 120px;
        margin: 0px;
        padding: 0px;
        border: 1px solid $color_5;
        display:block;
    }
    .CalenderDiv {
        width: 44%;
        display: inline-block;
        margin-top: 7px;
    }
    .SearchViewInput.cursor-ptr {
        margin-left: 1rem;
        width: 90%;
    }
    .EventReportTable{
        tbody {
            height: calc(100vh - 365px);
        }    
    }
    .DeviceCurrentStatusDiv .EventReportTable td {
        font-size: 14px;
        font-family: Segoe UI;
        font-weight: normal;
        line-height: 1.36;
        letter-spacing: normal;
        text-align: left;
        padding-left: 20px !important;
        cursor: default !important;
    }
    .HeaderSpanHR{
        margin: 15px;
        font-size: 15px;
        font-weight: 500;
        color: $color_5;
    }    
    .HMHeader .btn-outline-primary {
        height: 32px !important;
        line-height: 1;
    }
    .inputTypeSearch {
        border: 0px !important;
        background: none !important;
        font-size: 13px;
        /* line-height: 29px; */
        color: #202124;
        /* margin: 5px 40px 5px 5px !important; */
        width: 90%;
        height: 35px;
        padding-left: 7px;
        &::placeholder {
            color: #c5c5c5;
            font-size: 13px;
            line-height: 18px;
        }
    }
}