$color_1: #B8B8B8;
$color_2: #272727;
$color_3: #0b70d1;
$color_4: #BABABA;
$color_5: #575757;
$font-family_1: Segoe UI;
$background-color_1: #1F1A24;
$background-color_2: #f2f6fe;
$background-color_3: #f7f7fa;
$box-shadow1:rgb(112 111 111) 0px 0px 5px 0px inset, 0 0 6px 0 rgba(0, 0, 0, 0.3);;

  .darkTheme{
    .RefreshText {
      h4 {
        margin-bottom: 0;
        margin-top: 12px;
        font-size: 20px;
        font-weight: normal;
        font-family: Segoe UI;
        color: #B8B8B8;
        margin-right: 15px;

        height: 1.438rem;
        font-size: 1rem;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }
    }
    .integratedDeviceText {
      margin: 0;
      font-family: Segoe UI;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0.01px;
      color: #B8B8B8;
      @include devices(laptop_xl) {
        font-size: 12px;
      }
      @include devices(laptop_lg) {
        font-size: 11px;
      }
      @include devices(laptop_md) {
        font-size: 11px;
      }
      @include devices(laptop_sm) {
        font-size: 10px;
      }
    }
    .HeightScrollSitesGrid {
      height: calc(100vh - 145px);
    }
    .HMHeader {
      @include floatLeft;
    }
    .RowClass {
      @include floatLeft;
      width: 100%;
      padding: 10px 0px;
      margin: 0;
    }
    .CardBoxWithBorder {
      height: 160px;
      margin: 10px;
      @include CardBoxBGShadow;
      box-shadow: $box-shadow1;
      //box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
      background-color: $background-color_1;
      float: left;
      width: calc(100% - 20px);
    }
    .CardBoxImage {
      width: 55px;
      height: 55px;
      border-radius: 9px;
      text-align: center;
      @include flexCenter;
      @include floatLeft;
    }
    .CardBoxContent {
      margin: 20px;
      height: auto;
      @include  display-flex;
    }
    .CardBoxText {
      margin-left: 6px;
      width: 78%;
      // display: flex;
      // justify-content: space-between;
      h4 {
        margin-bottom: 0;
        margin-top: 12px;
        font-size: 20px;
        font-weight: normal;
        font-family: $font-family_1;
        color: $color_4;
        @include devices(laptop_lg) {
          font-size: 17px;
        }
        @include devices(laptop_md) {
          font-size: 16px;
        }
      }
      @include devices(laptop_lg) {
        // width: 70%;
      }
      @include devices(laptop_md) {
        width: 73%;
      }
      @include devices(laptop_sm) {
        width: 72%;
      }
    }
    .SystemHealthCardText{
        margin-bottom: 0;
        margin-top: 12px;
        font-size: 20px;
        font-weight: normal;
        font-family: $font-family_1;
        color: $color_4;
        float: left;
        margin-left: 5px;
    }
    .StatusText {
      @include  display-flex;
      margin-top: 35px;
    }
    .StatusText1 {
      margin-top: 5px;
    }
    .StatusText2 {
      margin-top: 30px;
    }
    .StatusText3 {
      margin-top: 30px;
    }
    #tooltip1 {
      @include  display-flex;
      flex-direction: column;
      height: auto;
    }
    #tooltip2 {
      @include  display-flex;
      flex-direction: column;
      height: auto;
    }
    #tooltip3 {
      >.tooltip-inner {
        @include  display-flex;
        flex-direction: column;
        height: auto;
      }
    }
    .timeTooltipCount {
      max-width: 150px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .deviceNameTooltipText {
      width: 100px;
      @include floatLeft;
    }
    .deviceNameTooltipName {
      width: 60px;
      word-break: keep-all;
      @include floatLeft;
    }
    .siteNameTooltipText {
      width: 80px;
      @include floatLeft;
    }
    .siteNameTooltipName {
      width: 80px;
      @include floatLeft;
      word-wrap: break-word;
    }
    .statusValueText {
      margin: 0;
      font-family: $font-family_1;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0.01px;
      color: $color_1;
    }
    .statusValueP {
      margin: 0;
      font-weight: 500;
      line-height: 18px;
      font-family: $font-family_1;
      font-size: 25px;
      font-weight: 600;
      letter-spacing: 0.03px;
      color: $color_4;
    }
    .OnlySingleValue {
      margin-top: 50px;
      text-align: center;
      width: 90%;
    }
    .DeviceCurrentStatusDiv {
      margin: 20px 10px 15px 10px;
      @include floatLeft;
      @include CardBoxBGShadow;
      // box-shadow: $box-shadow1;
      background: $background-color_1;
      td {
        cursor: pointer;
        font-size: 14px;
        font-family: $font-family_1;
        font-weight: normal;
        line-height: 1.36;
        letter-spacing: normal;
        text-align: left;
        padding-left: 20px !important;
      }
      .online-color {
        font-weight: 600 !important;
        color: #7BB526;
      }
      .offline-color {
        font-weight: 600 !important;
        color: #F32C22;
      }
    }
    .DeviceTableHeader {
      border-bottom: 1px solid #e6e6e6;
      @include flexSpaceBetween;
      p {
        margin: 15px;
        font-size: 15px;
        font-weight: 600;
        color: $color_4;
        // margin-bottom: 0px;
      }
      span{
        color: $color_4;
      }
    }
    .TrendchartDevice {
      height: 300px;
      border: 1px solid #a5a5a5;
      border-radius: 7px;
      margin: 10px;
      padding: 10px;
      @include floatLeft;
      width: 98%;
      @include devices(laptop_lg) {
        height: 230px;
      }
    }
    .LegendModelDiv {
      @include floatLeft;
      margin-left: 10px;
    }
    .MainLegendDiv {
      @include  display-flex;
      align-items: center;
      p{
        color: $color_4;
      }
    }
    .LegendColorDiv {
      width: 25px;
      height: 6px;
      border-radius: 3px;
      margin-right: 2px;
    }
    .healthMonitorModel {
      .healthmonitoringTable {
        margin: 10px auto;
      }
      .close {
        line-height: 1.5;
        color: #0B70D1 !important;
      }
      .modal-content{
        background-color: #3d3d3d;
      }
    }
    .deviceModelHeader {
      width: 100%;
      @include flexSpaceBetween;
      .btn-outline-primary {
        height: 34px;
        margin-top: 5px;
        line-height: 10px;
        color: $color_3 !important;
        &:hover {
          color: $color_4 !important;
        }
      }
      p{
        color: $color_4;
      }
    }
    .rs-picker-menu {
      // z-index: 10000;
      z-index: 99999;
      background-color: #070612;
    }
    .rs-calendar-table-cell{
      color: #fff;
    }
    .rs-picker-toggle-textbox{
      background-color: #484848;
    }
    .rs-picker-default .rs-btn, 
    .rs-picker-default .rs-picker-toggle{
      background-color: #484848 !important;
    }
    .width60pct {
      width: 60%;
      background-color: #918e8e;
    }
    .width70pct {
      width: 70%;
      background-color: #918e8e;
    }
    .width80pct {
      width: 80%;
      background-color: #918e8e;
    }
    .HMWidgetLabelSkeleton {
      width: 75%;
      height: 10px;
    }
    .HMWidgetValueSkeleton {
      width: 50%;
      height: 10px;
      @include floatLeft;
    }
    .offlineDeviceText {
      margin: 0;
      font-family: $font-family_1;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0.01px;
      color: $color_4;
      display: inline-block;
      margin-bottom: 0px;
      width: 95px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-left: 3px;
      margin-top: 3px;
    }
    .OfflineDeviceDiv {
      @include  display-flex;
      align-items: center;
      width: 60px;
    }
    .CameraTamperingModel {
      tbody {
        height: calc(100vh - 600px) !important;
      }
    }
    .CameraInfoDiv {
      @include absolutePosition;
      right: -35px;
      top: -10px;
      @include devices(laptop_xl) {
        right: -8px
      }
      @include devices(laptop_lg) {
        right: -8px
      }
      @include devices(laptop_md) {
        @include absolutePosition;
        right:-15px;
        top:-10px;
      }
      @include devices(laptop_sm) {
        @include absolutePosition;
        right:-15px;
        top:-10px;
      }
    }
    .TrendSelectionDateP {
      margin-bottom: 0px;
      text-align: end;
      margin-right: 10px;
      font-size: 13px;
      font-weight: normal;
      @include floatRight;
      color: $color_4;
    }
    .DonutChartWidthDiv {
      width: 55%;
    }
    .InfoImg {
      img {
        vertical-align: top;
        height: 14px;
      }
    }
    .offlineDevDiv {
      width: 60%;
    }
    .noRecordFound {
      @include relativePosition;
      height: 100%;
    }
    .noRecordFoundText {
      @include absolutePosition;
      top: 50%;
      left: 46%;
    }
    .OnHoverMouseTableRow {
      tr {
        &:hover {
          td {
            // background-color: $background-color_2 !important;
            text-decoration: underline;
          }
        }
      }
    }
    .PaginationDiv {
      &:disabled {
        opacity: .3;
        background-color: $background-color_3 !important;
        color: $color_5;
      }
    }
    .FromDateFontSize {
      font-size: 14px !important;
      font-weight: 500 !important;
      color: $color_4;
    }
    .HMWidgetMainDiv {
      background-color: #1F1A24;
      border-radius: 13px;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
      height: 380px;
      margin: 10px 10px 15px 10px;
      padding: 10px; 
    }
    .CarouselPrev {
      // background-color: #1F1A24;
    }
    .CarouselNext {
      // background-color: #1F1A24;
    }
    .alice-carousel__dots {
      display: none;
    }
    .IntegratedTextDiv{
      float: left;
      width: 48%;
    }
    .IntegratedChartDiv{
      float: right;
      width: 45%;
    }
}
