
$color_1: #fff;
$color_2: #1d1d1d;
$color_3: #717171;
$color_4: #0000ff;
$color_5: #cfcfcf;
$background-color_1: #fff;
$background-color_2: #0b70d1;
$background-color_3: #000000;
$background-color_4: #f2f6fe;
$box-shadow_1: 0 0 6px 0 rgba(0, 0, 0, 0.3);
$box-shadow_2: 0 2px 2px 0 rgba(0, 0, 0, 0.2);

.lightTheme{

    progress[value] {
        appearance: none; /* Needed for Safari */
        border: none; /* Needed for Firefox */
        color: $background-color_2; /* Fallback to a solid color */
      }
      
      /* WebKit styles */
      progress[value]::-webkit-progress-value {
        background-image: linear-gradient(
          to right,
          #ff8a00, $background-color_2
        );
        transition: width 1s linear;
      }
      
      /* Firefox styles */
      progress[value]::-moz-progress-bar {
        background-image: -moz-linear-gradient(
          right,
          #ff8a00, $background-color_2
        );
      }

    .rightcameradiv {
        @include floatRight;
        @include flexCenter;
        width: auto;
        justify-content: end;
        @include devices(laptop_xxl) {
            width: 36%;
        }  
        @include devices(laptop_xl) {
            width: 40%;
        }  
        @include devices(laptop_lg) {
            width: 45%;
        }  	
        @include devices(laptop_md) {
            width: 50%;
        }  	
    }
    .cam-search {
        @include floatLeft;
        border-radius: 8px;
        background-color: $background-color_1;
        padding-left: 10px;
        width: 22rem;
        .SearchViewInput {
            width: 95%;
        }
    }
    .mosaicdiv {
        width: auto;
        align-items: center;
        @include display-flex ;
        @include floatRight;
    }
    .playbackbuttonNewUI {
        width: 120px;
        height: 32px;
        border-radius: 7px;
        background-color: $background-color_2;       
        color: $color_1;
        margin-left: 5px;
        @include display-flex ; 
        justify-content: space-around;
        align-items: baseline;
    }
    .mosaicbuttonNewUI {
        width: 80px;
        height: 32px;
        border-radius: 7px;
        background-color: $background-color_2;
        color: $color_1;
        margin-left: 5px;
        @include display-flex ; 
        justify-content: space-around;
        align-items: baseline;
    }
    .downloadbuttonNewUI {
        padding: 0.3rem;
        border-radius: 7px;
        background-color: $background-color_2;
        color: $color_1;
        margin-left: 5px;
        @include display-flex ; 
        justify-content: space-around;
        align-items: baseline;
    }
    .playbackbuttonButtonText {
        font-size: 0.75rem;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin: auto;
        margin-left: 0;
    }
    .CameraGridNewUI {
        width: 98%;
        height: auto;
        padding: 0 0 6px;
        border-radius: 7px;
        box-shadow: $box-shadow_1;
        background-color: $background-color_1;
        
    }

    #slider {
        margin-top: 10px;
      }
      
      #slidertitle {
        display: none;
        position: absolute;
        cursor: default;
        top: 0;
        left: 0;
      }
      
      #container:hover > #slidertitle {
        display: block;
      }
      
    .cameraHoverMainDiv {
        position: relative;
        width: 100%;
    }
    .CameraDiv1 {
        @include relativePosition;
        width: auto;
        min-width: 340px;
        margin: 0px;
        display: inline-block;
        :hover .cameraButtonsMainDiv {
            display: flex;
            bottom: 5px;
        }
    }
    .cameraButtonsMainDiv {
        display: none;
        position: absolute;
        bottom: 2px;
        background-color: rgba(0, 0, 0, 0.6);
        padding: 10px;
    }
    .CameraNameTextValue {
        width: 335px;
        font-size: 14px;
        letter-spacing: normal;
        text-align: left;
        color: $color_2;
        margin: 0;
        font-weight: 500;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .LabelDivWithButton {
      @include flexSpaceBetween;
    }
    .playbackbtn {
        background-color: transparent;
        border: 1px solid transparent;
        line-height: 45px;
        padding: 0;
    }
    .PlayBackBtnDiv {
        width: 30px;
        height: 30px;
        object-fit: contain;
        border-radius: 25px;
        box-shadow: $box-shadow_2;
        background-color: $background-color_2;
        @include flexCenter;
        margin-left: 5px;
        img {
            margin-right: 2px;
        }
    }
    .CameraDetailsText {
        height: 16px;
        font-size: 12px;
        font-weight: normal;
        text-align: left;
        color: $color_3;
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
    }
    .CameraDetailsDiv {
        margin-top: 5px;
    }
    .HeightScrollCameraGrid {
        height: calc(100vh - 150px);
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        margin-right: 15px;
    }
    .camera-list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 300px;
        grid-gap: 10px;
        margin-bottom: 10px;
        margin-top: 5px;
        @include devices(laptop_xl) {
            grid-template-columns: repeat(3, 1fr);
        }  	
        @include devices(laptop_md) {
            grid-template-columns: repeat(3, 1fr);
        }  	
    }
    .camera-list .mb-0 {
        width: 150px;
    }
    .player-responsive1 {
        @include relativePosition;
        width: 100% !important;
        height: 200px !important;
        padding: 0.2rem 0.2rem;
        background-color: $background-color_3;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
        .player-responsive-fullScreen {
            @include relativePosition;
            width: 100% !important;
            height: 100% !important;
            padding: 0.2rem 0.2rem;
            background-color: $background-color_3;
        }
    .playbackcalendersection {
        height: 50px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: $box-shadow_1;
        border-radius: 3px;
        padding: 10px;
        margin: 5px;
        @include flexSpaceBetween;
    }
    .camera-checkbox {
        width: 22px;
        height: 22px;
        border-radius: 5px;
        border: 2px solid;
        display: inline-block;
        vertical-align: middle;
        padding: 2px;
        cursor: pointer;
        &:checked {
            content: "";
            padding: 2px;
            width: 22px;
            height: 22px;
            border-width: 0 3px 3px 0;
            transform: rotate(359deg);
            border-bottom: 10px solid $color_4;
            border-top: 10px solid  $color_4;
            border-bottom: 10px solid  $color_4;
            border-top: 10px solid  $color_4;
        }
        &:checked+.label-for-check {
            border: 4px solid red;
            box-shadow: 5px 10px red;
        }
    }
    .react-calendar{
        background-color: $background-color_1;
        width: 340px !important;
    }
    
    .playback {
        @include display-flex;
        flex-direction: column;
        padding-left: 1.5rem;
    }
    
    .playbackcameradiv {
        @include display-flex;
        padding-top: 0.5rem;
        flex-direction: row;
    }
    
    .playbackcameradiv_one {
        flex: 1
    }
    
    .playbackcameradiv_one-link {
        text-decoration: none;
    }
    
    .playbackcameradiv_one > p {
        font-size: 1.2rem;
        color: black;
    }
    
    .playbackcameradiv_three {
        margin-left: 0.2rem;
    }
    
    .playback__datepickers {
        @include display-flex;      
        align-items: center;
        gap: 1rem;
        height: 50px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: $box-shadow_1;
        border-radius: 3px;
        padding-left: 10px;
    }
    
    .endDateStringSinglePlayback {
        padding-top: 0.5rem;
    }
    .singleInputDiv {
        @include display-flex; 
        flex-direction: row;
        border: 1px solid $color_5;
        padding: 5px;
        border-radius: 7px;
    }
    .single-cam-playback-main {
        height: 700px;
        @include devices(laptop_xl) {
            height: 685px;
        }  
        @include devices(laptop_lg) {
            height: 535px;
        }  	
        @include devices(laptop_md) {
            height: 535px;
        } 
        @include devices(laptop_sm) {
            height: 405px;
        }  	
    }
    .single-cam-playback-sub {
        background-color: none !important;
        box-shadow: none !important;
    }
    .single-cam-playback-player {
        height: 100% !important;
        width: 100% !important;
    }
    .singleInput {
         border: none;
         background-color: transparent;
     }
     
     .singleInput:focus {
         outline: none;
     }
     .singlePlayebackHover {
        position: relative;
        height: 765px;
        @include devices(laptop_xxl) {
            height: 735px;
        } 
        @include devices(laptop_xl) {
            height: 735px;
        }
        @include devices(laptop_lg) {
            height: 585px;
        } 
        @include devices(laptop_md) {
            height: 585px;
        }
        @include devices(laptop_sm) {
            height: 465px;
        }
        &:hover .mosaicButtonsMainDiv {
            display: flex;
            bottom: 1px;
        }
    }
    .changeColor {
        cursor: pointer;
        fill: black;
    }

    .changeColor:hover {
        cursor: pointer;
        fill: #0B70D1;
    }
    
    .playbackicon {
        margin: auto;
    }
    
    .no-stream-found {
        height: 200px;
        border-radius: 7px 7px 0px 0px;
        border-bottom: 1px solid #e2e2e2;
    }

    .CameraNameText1 {
        font-size: 1rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8rem;
        letter-spacing: normal;
        text-align: left;
        color: #0f6689;
        margin-bottom: 0rem;
    }
    
    .player-responsive1 > video {
        @include relativePosition;
    }

        .player-responsive-fullScreen>video {
            @include relativePosition;
        }
    .mosaic-nostream-img {
        height: 100%;
        border-radius: 7px 7px 0px 0px;
    }

    .cam-name {
        width: 100% !important;
    }

    .location-name {
        width: 125% !important;
    }

    .cam-pg {
        color: #8A8A8A !important;
    }

    .mosaic-pg {
        color: #ffffff;
    }        
    #endDateStringSinglePlayback{
        margin-bottom: 0px;
    }

    .single-cam-playback-controls {
        background-color: #343434;
        margin-top: 0.1rem;
        border-radius: 0.5rem;
        height: 45px;
        @include display-flex; 
        flex-direction: row;
        color: white;
        padding: 0rem 0.5rem;
        font-size: 0.8rem;
    }
    .single-cam-playback-hover-controls {
        width: 100%;
        margin-top: 0.1rem;
        border-radius: 0.5rem;
        height: 45px;
        @include display-flex; 
        flex-direction: row;
        color: white;
        font-size: 0.8rem;
    }
    .single-cam-playback-controls__left {
        width: 3%;
    }

    .controls_seconds {
        font-size: 0.7rem;
        color: #B0B0B0;
    }

    .controls_pause {
        border: 2px solid $color_1;
        border-radius: 50%;
        display: grid;
        place-items: center;
        height: 35px;
        margin-top: 10%;
        width: 35px;
        margin-left: 10%;
        font-weight: bold;
        cursor: pointer;
    }

    .single-cam-playback-controls__right {
        flex: 1;
        @include display-flex; 
        flex-direction: column;
        padding: 0.5rem;
    }

    .single-cam-playback-controls__right-upper {
        @include display-flex; 
        flex-direction: row;
        justify-content: space-between;

    }
    .Singleplaybackbuttons {
        float: left;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .SingleplaybackButtonDiv {
        width: 35px;
        height: 35px;
        object-fit: contain;
        border-radius: 25px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
        background-color: #343434;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
    }
    .controls_video {
        @include display-flex; 
        gap: 0.5rem;
    }
    .controls_seekbar {
        width: 100%;
    }
    video::-webkit-media-controls-panel {
        background-image: linear-gradient(transparent, transparent) !important;
    }
    .table-fixed tbody tr:hover td {
        background-color: $background-color_4;
    }
    .singleplaybackexportModal {
        z-index: 99999;
        .modal-content{
            height: 495px;
            width: 335px;
        }
    }
    .ExportModal {
        z-index: 99999;
        .modal-content {
            height: 560px;
            width: 335px;
        }
    }
    .singleplaybackNoStreamdiv {
        height: 750px;
        @include devices(laptop_xl) {
            height: 730px;
        }  
        @include devices(laptop_lg) {
            height: 585px;
        }  	
        @include devices(laptop_md) {
            height: 730px;
        }
        @include devices(laptop_sm) {
            height: 445px;
        }
    }

    // .modal-lg {
    //     max-width: 1200px !important;
    // }
    #output {
        width: auto;
        max-width: 1200px;
        overflow: auto;
        height: auto;
        max-height: 600px;
        @include devices(laptop_sm) {
            height: auto;
            max-height: 458px !important;
            overflow: auto;
        }
    }
    // .Button_module_buttonChildren {
    //     overflow: hidden;
    //     white-space: nowrap;
    //     text-overflow: ellipsis;
    // }
    // .Tooltip_module_tooltip {
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     left: 50%;
    //     color: #fff;
    //     background: rgba(0,0,0,.9);
    //     font-size: 12px;
    //     letter-spacing: 0;
    //     bottom: 2.66667em;
    //     padding: 0.66667em 1em;
    //     height: 2.66667em;
    //     line-height: 1.25em;
    //     border-radius: 4px;
    //     transition: opacity .15s ease-out;
    //     position: absolute;
    //     font-weight: 700;
    //     opacity: 0;
    //     pointer-events: none;
    //     white-space: pre;
    //     transform: translateX(-50%);
    // }
}